import { createStore } from 'vuex'


export default createStore({
  state: {
    screenWidth: document.body.clientWidth
  },
  getters: {
    screenWidth (state) {
      return state.screenWidth;
    }
  },
  mutations: {
    increment(state, width) {
      // 变更状态
      state.screenWidth = width;
    }
  },
  actions: {},
  modules: {}
})